import React, { useState } from 'react';
import AddButton from '@components/AddButton';
import DynamicTable from '@components/DynamicTable';
import HeaderTitle from '@components/HeaderTitle';
import { EditRounded } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useCommonStyles } from '@styles/common.style';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import serviceMobileSheets from '@services/serviceMobileSheets';
import { snackActions } from '@helpers/snackbarUtils';
import Dialog from '@components/Dialog'
import { Box, Button } from '@mui/material'
import Flags from '@components/Flags'
import { useFlags } from '@hooks/useFlags'

const OwToolbox = () => {
    const classes = useCommonStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [ openLanguage, setOpenLanguage ] = useState(false);
    const { flagOptions, FlagSelected } = useFlags()
    const [language, setLanguage] = useState(1)
    const [selectedIdMobileSheet, setSelectedIdMobileSheet] = useState(null)

    const ORDER = [
        /*{
            "orderField": "mobileSheetTitle",
            "orderAsc": true
        }*/
    ];
    const COLUMNS = [
        {
            accessor: '',
            Header: 'Info',
            filterable: false,
            hideHeader: true,
            width: '5%',
            Cell: ({ row: { values } }) => (
                <IconButton
                    aria-label='info'
                    color='info'
                    style={{
                        padding: '0px',
                    }}
                    onClick={() => {
                        handleShowMobileSheetDetail(values.idMobileSheet);
                    }}>
                    <EditRounded fontSize='inherit' />
                </IconButton>
            ),
        },
        {
            accessor: 'idMobileSheet',
            Header: t('LABEL.ID'),
            filterable: false,
            width: '1%',
        },
        {
            accessor: 'mobileSheetName',
            Header: t('LABEL.NAME'),
            width: '40%'
        },
        {
          accessor: 'mobileSheetCode',
          Header: t('OWTOOLS.WORKSHEET_UNLOCK_CODE'),
          width: '59%'
      },
    ];

    const handleShowMobileSheetDetail = idMobileSheet => {
        const userSession = JSON.parse(sessionStorage.getItem('userSession'))
        if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
            setOpenLanguage(true)
            setSelectedIdMobileSheet(idMobileSheet)
        } else {
            snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
        }
    };

    const handleGoToDetails= () => {
        sessionStorage.setItem('idLanguage', language)
        if (selectedIdMobileSheet !== null) {
            serviceMobileSheets.getMobileSheetsDetails(selectedIdMobileSheet).then(response => {
                if (!response.error) {
                    navigate('/owToolboxDetail', {
                        state: { data: response.data, type: 'UPDATE' },
                    });
                }
            })    
        } else {
            navigate('/owToolboxDetail', { state: { type: 'NEW' } }); 
        }
    }
    

    const handleNewTarget = () => {
        const userSession = JSON.parse(sessionStorage.getItem('userSession'))
        if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 4)) {
            setOpenLanguage(true)
        } else {
            snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
        }
    };

    const getIdLang = (lang) => {        
        if (lang === 'es') {
            return 1;
        } else if (lang === 'en') {
            return 2;
        } else if (lang === 'it') {
            return 3;
        } else {
            return 1;
        }      
    }
    return (
        <div className={classes.container}>
            <Dialog
                open={openLanguage}
                close={() => setOpenLanguage(false)}
                title={t('TEXT.LANGUAGES')}
                subtitle={t('TEXT.SELECT_LANGUAGE')}
                width={300}
                content={
                    <Flags
                    options={flagOptions}
                    handleSelect={e => {
                        setLanguage(getIdLang(e.target.value))
                    }}
                    />
                }
                actions={
                    <Box display='flex' alignItems='center'>
                    <Box mr={1}>
                        <Button
                        variant='contained'
                        onClick={() => setOpenLanguage(false)}
                        color='error'
                        >
                        {t('BUTTON.CANCEL')}
                        </Button>
                    </Box>
                    <Button
                        variant='contained'
                        onClick={handleGoToDetails}                     
                    >
                        {t('BUTTON.OK')}
                    </Button>
                    </Box>
                }
            />
            <HeaderTitle
                title={t('TEXT.TITLE_TEMPLATE')}
                subtitle={t('TEXT.SUBTITLE_TEMPLATE')}>
                <AddButton text={t('BUTTON.NEW_TEMPLATE_OWTOOL')} handleOnclick={handleNewTarget} />
            </HeaderTitle>
            <DynamicTable
                columns={COLUMNS}
                endpoint='MobileSheets/List'
                isGlobalSearch={true}
                isColumnsSearch={true}
                isOrdered={true}
                orderColumn={ORDER}
            />
        </div>
    );
};

export default OwToolbox;