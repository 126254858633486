import React, {useState, useEffect, useMemo, useCallback, useRef} from 'react';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import ColorPicker from '@components/ColorPicker';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import {
    Button,
    ButtonGroup,
    Grid,
    TextField,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import {makeStyles} from '@mui/styles';
import FlagInput from './FlagInput';
import DeletePopover from '@components/DeletePopover';
import {useTranslation} from 'react-i18next';
import CheckBox from '@components/CheckBox'
import Editor from '@components/CkEditor';

function SelectTypes({item, index, handleChangeItems, typesList}) {
    const idParent = item.idMobileSheetItemType
        ? typesList.filter(
            type => type.idMobileType === item.idMobileSheetItemType,
        )[0]?.idMobileTypeParent
        : 1;

    const [parentTypeId, setParentTypeId] = useState(idParent);
    
    // Selección del valor inicial de idMobileSheetItemType
    if (!item.idMobileSheetItemType && typesList.length > 0) {
      const filteredTypes = typesList.filter(type => type.idMobileTypeParent === parentTypeId);
      if (filteredTypes.length > 0) {
          item.idMobileSheetItemType = filteredTypes[0].idMobileType;
      }
    }

    // Ordenar typesList para el select de variantes por idMobileType en orden creciente
    const sortedVariantTypesList = typesList
        .filter(type => type.idMobileTypeParent === parentTypeId)
        .sort((a, b) => a.idMobileType - b.idMobileType);

    useEffect(() => {
        if (!item.idMobileSheetItemType && sortedVariantTypesList.length > 0) {
            handleChangeItems('idMobileSheetItemType', sortedVariantTypesList[0].idMobileType, index);
        }
    }, [sortedVariantTypesList, index, item, handleChangeItems]);


        // Manejar de cambios de parentTypeId
        const handleParentTypeChange = (event) => {
          const newParentTypeId = event.target.value;
          setParentTypeId(newParentTypeId);
      
          // Filtrar y ordenar la lista según nuevo parentTypeId
          const filteredTypes = typesList
              .filter(type => type.idMobileTypeParent === newParentTypeId)
              .sort((a, b) => a.idMobileType - b.idMobileType);
          if (filteredTypes.length > 0) {
              // Actualiza el valor de item.idMobileSheetItemType con el primer valor
              handleChangeItems('idMobileSheetItemType', filteredTypes[0].idMobileType, index);
          }
        };
      
    
    const {t} = useTranslation();

    // TODO: quitar  {type.idMobileType} para que no salgan los ID
    return (
        <>
            <Grid item xs={6} lg={3}>
                <Typography variant='caption' component='div'>
                    {t('LABEL.ELEMENT')}
                </Typography>

                <Select
                    fullWidth
                    size='small'
                    value={parentTypeId}
                    onChange={handleParentTypeChange}>
                    {typesList.map(
                        type =>
                            !type.idMobileTypeParent && (
                                <MenuItem value={type.idMobileType} key={type.idMobileType}>
                                    {type.mobileTypeName}
                                </MenuItem>
                            ),
                    )}
                </Select>
            </Grid>
            <Grid item xs={6} lg={3}>
                <Typography variant='caption' component='div'>
                    {t('LABEL.VARIANT')}
                </Typography>
                <Select
                  fullWidth
                  size='small'
                  value={item?.idMobileSheetItemType || ''}
                  onChange={e => {
                      const {name, value} = e.target;
                      handleChangeItems(name, value, index);
                  }}
                  name='idMobileSheetItemType'>
                  {sortedVariantTypesList.map(
                      type => (
                          <MenuItem value={type.idMobileType} key={type.idMobileType}>
                              {type.mobileTypeName}
                          </MenuItem>
                      ),
                  )}
              </Select>
            </Grid>
        </>
    );
}

const DraggableElements = ({onDragEnd, selectedLanguage, formData, setFormData, typesList, onUpdateItem}) => {                               
        const useStyles = makeStyles({
            draggingListItem: {
                background: 'rgb(235,235,235)',
                borderRadius: 4,
            },
            notDraggingListItem: {
                borderRadius: 4,
                border: '1px solid rgb(235,235,235)',
            },
        });
        const classes = useStyles();
        const {t} = useTranslation();
        const [disableDrag, setDisableDrag] = useState(false);

        
        
        const handleChangeItems = (name, value, index) => {
            // Actualizar el elemento específico en mobileSheetItems
            
            const updatedItems = formData.mobileSheetItems.map((item, idx) => {
                if (idx === index) {
                    return { ...item, [name]: value };
                }
                return item
            });
                    
            // Actualizar el estado global de formData
            setFormData((prevState) => ({
                ...prevState,
                mobileSheetItems: updatedItems,
            }));
        };

        const getSelectColor = (selected, key) => {
            return selected === key ? 'success' : 'primary';
        };

        const handleDeleteItem = (index) => {
            // Filtrar los items para eliminar el seleccionado
            const updatedItems = formData.mobileSheetItems.filter((_, idx) => idx !== index);
        
            // Actualizar el estado de formData con los elementos restantes
            setFormData((prevState) => ({
                ...prevState,
                mobileSheetItems: updatedItems.map((item, idx) => ({
                    ...item,
                    mobileSheetItemOrder: idx + 1, // Actualizar el orden después de eliminar
                })),
            }));
        };
        
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable-list'>
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {formData.mobileSheetItems.map((item, index) => {                                                             
                                return (<Draggable
                                    draggableId={item.idDrag}
                                    index={index}
                                    key={item.idDrag}
                                    isDragDisabled={disableDrag}>
                                    {(provided, snapshot) => (
                                        <Grid
                                            container
                                            mb={2}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={
                                                snapshot.isDragging
                                                    ? classes.draggingListItem
                                                    : classes.notDraggingListItem
                                            }>
                                            <Grid container spacing={1} p={2}>
                                                <SelectTypes
                                                    item={item}
                                                    index={index}
                                                    handleChangeItems={handleChangeItems}
                                                    typesList={typesList}
                                                />

                                                <Grid item xs={6} sm={1.6} lg={0.8}>
                                                    <Typography variant='caption' component='div'>
                                                        {t('LABEL.TEXT')}
                                                    </Typography>
                                                    <ColorPicker
                                                        bgColor={item.mobileSheetItemTextColor}
                                                        key={`mobileSheetItemTextColor${item.id}`}
                                                        setDisableDrag={setDisableDrag}
                                                        disableDrag={disableDrag}
                                                        onChangeComplete={e => {
                                                            handleChangeItems(
                                                                'mobileSheetItemTextColor',
                                                                e.hex,
                                                                index,
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} sm={1.6} lg={0.8}>
                                                    <Typography variant='caption' component='div'>
                                                        {t('LABEL.BACKGROUND')}
                                                    </Typography>
                                                    <ColorPicker
                                                        bgColor={item.mobileSheetItemBackColor}
                                                        key={`mobileSheetItemBackColor${item.id}`}
                                                        setDisableDrag={setDisableDrag}
                                                        disableDrag={disableDrag}
                                                        onChangeComplete={e => {
                                                            handleChangeItems(
                                                                'mobileSheetItemBackColor',
                                                                e.hex,
                                                                index,
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item lg={2.4}>
                                                    <Typography variant='caption' component='div'>
                                                        {t('LABEL.ALIGNMENT')}
                                                    </Typography>
                                                    <ButtonGroup
                                                        disableElevation
                                                        variant='contained'
                                                        fullWidth>
                                                        <Button
                                                            onClick={e => {
                                                                handleChangeItems(
                                                                    'mobileSheetItemAlign',
                                                                    'left',
                                                                    index,
                                                                );
                                                            }}
                                                            color={getSelectColor(
                                                                item.mobileSheetItemAlign,
                                                                'left',
                                                            )}>
                                                            <FormatAlignLeftIcon />
                                                        </Button>
                                                        <Button
                                                            onClick={e => {
                                                                handleChangeItems(
                                                                    'mobileSheetItemAlign',
                                                                    'center',
                                                                    index,
                                                                );
                                                            }}
                                                            color={getSelectColor(
                                                                item.mobileSheetItemAlign,
                                                                'center',
                                                            )}>
                                                            <FormatAlignJustifyIcon />
                                                        </Button>

                                                        <Button
                                                            onClick={e => {
                                                                handleChangeItems(
                                                                    'mobileSheetItemAlign',
                                                                    'right',
                                                                    index,
                                                                );
                                                            }}
                                                            color={getSelectColor(
                                                                item.mobileSheetItemAlign,
                                                                'right',
                                                            )}>
                                                            <FormatAlignRightIcon />
                                                        </Button>
                                                    </ButtonGroup>
                                                </Grid>
                                                <Grid item xs={6} sm={2.3} lg={1}>
                                                    <CheckBox
                                                        style={{display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1', textAlign: 'center'}}
                                                        label={t('LABEL.START')}
                                                        name='isOpenWorksheet'
                                                        value={item?.isOpenWorksheet || ''}
                                                        checked={item?.isOpenWorksheet}
                                                        onChange={e => {
                                                            handleChangeItems(
                                                                'isOpenWorksheet',
                                                                e.target.checked,
                                                                index,
                                                            );
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6} sm={2.3} lg={1}>
                                                    <CheckBox
                                                        style={{display: 'flex', flexDirection: 'column', backgroundColor: '#f1f1f1', textAlign: 'center'}}
                                                        label={t('LABEL.END')}
                                                        name='isCloseWorksheet'
                                                        value={item?.isCloseWorksheet || ''}
                                                        checked={item?.isCloseWorksheet}
                                                        onChange={e => {
                                                            handleChangeItems(
                                                                'isCloseWorksheet',
                                                                e.target.checked,
                                                                index,
                                                            );
                                                        }}
                                                    />
                                                </Grid>

                                                {
                                                    (item.idMobileSheetItemType === 53 ||
                                                        item.idMobileSheetItemType === 54 ||
                                                        item.idMobileSheetItemType === 55 ||
                                                        item.idMobileSheetItemType === 56 ||
                                                        item.idMobileSheetItemType === 58) &&
                                                    <>
                                                        <Grid item lg={3}></Grid>
                                                        <Grid item xs={1.5} lg={1}>
                                                            <Typography variant='caption' component='div'>
                                                                {t('LABEL.NUMBER')}
                                                            </Typography>
                                                            <TextField
                                                                size='small'
                                                                name='mobileSheetItemNumber'
                                                                fullWidth
                                                                value={item.mobileSheetItemNumber || ''}
                                                                onChange={e => {
                                                                    handleChangeItems(
                                                                        'mobileSheetItemNumber',
                                                                        e.target.value,
                                                                        index,
                                                                    );
                                                                }}
                                                                inputProps={{maxLength: 2}}
                                                            />
                                                        </Grid>
                                                    </>
                                                }

                                                <Grid item xs={12}>
                                                    <Typography variant='caption' component='div'>
                                                        {t('LABEL.CONTENT')}
                                                    </Typography>

                                                    <Grid container spacing={2} alignItems='center'>
                                                        <Grid item xs={11}>
                                                            <Editor
                                                                data={item?.mobileSheetItemLocale?.find(
                                                                    (locale) => locale.idLanguage == selectedLanguage
                                                                )?.mobileSheetItemContent || ''}
                                                                // data={'A' + selectedLanguage || 'AAA'}
                                                                onChange={(newData) => {                                                                    
                                                                   if (newData) {                                                                                                                                                                                                          
                                                                    const currentContent = item?.mobileSheetItemLocale?.find(
                                                                        (locale) => locale.idLanguage == selectedLanguage
                                                                    )?.mobileSheetItemContent;                                                                 
                                                            
                                                                    if (newData !== currentContent) {                                                                        
                                                                        onUpdateItem(newData, index, item.idDrag);
                                                                    }
                                                                   }
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <DeletePopover
                                                                remove={() => {
                                                                    handleDeleteItem(index);
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Draggable>
                            )})}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }


export default DraggableElements;