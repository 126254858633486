import Card from '@components/Card';
import DeletePopover from '@components/DeletePopover';
//import Dialog from '@components/Dialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
    Box,
    Button,
    ButtonGroup,
    Grid,
    Divider,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography,
    ListItemIcon,
    Checkbox,
    Tooltip,
    IconButton,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle
} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import TableParticipants from './TableParticipants';
import serviceRabbitMq from '@services/serviceRabbitMq';
import serviceFiles from '@services/serviceFiles'
import {snackActions} from '@helpers/snackbarUtils';
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import {styled} from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { Grading, School, Flag, Summarize, Feed, MailRounded } from '@mui/icons-material';
import serviceEvaluatorData from '@services/serviceEvaluatorData';

const Accordion = styled(props => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0
    },
    '&:before': {
        display: 'none'
    }
}))

const AccordionSummary = styled(props => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{fontSize: '0.9rem'}} />}
        {...props}
    />
))(({theme}) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)'
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1)
    }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)'
}))

const SectionParticipants = ({
    formData,
    setFormData,
    handleChange
}) => {
    const {t} = useTranslation();
    const [openTable, setOpenTable] = useState(false);
    const [participantsSelected, setParticipantsSelected] = useState([]);
    const [participantChecked, setParticipantChecked] = useState([]);
    const [allCheked, setAllChecked] = useState(false);
    const [listReload, setListReload] = useState(0);
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const [currentParticipant, setCurrentParticipant] = useState(null);
  
    const handleOpenConfirmDialog = (participant) => {
      setCurrentParticipant(participant);
      setOpenConfirmDialog(true);
   };

    const handleCheckbox = (e) => {

        if (!e.target.checked) {
            const quitParticipantCheck = participantChecked.filter(
                participant => participant !== e.target.value
            );
            setParticipantChecked(quitParticipantCheck);

        } else {
            const newParticipantCheck = [...participantChecked, e.target.value];
            setParticipantChecked(newParticipantCheck);
        }
    };

    const checkList = (participant) => {
        const checkItems = participantChecked.indexOf(participant) !== -1 ? true : false;
        return checkItems;
    }

    const handleAllCheck = (e) => {
        if (!e.target.checked) {
            setParticipantChecked([]);
        } else if (e.target.checked) {
            const tempArray = [];
            participantsSelected.forEach((participant) => {
                tempArray.push(participant.participantEmail);
            });
            setParticipantChecked(tempArray);
        }
        setListReload(Math.random());
    };

    useEffect(() => {
        setParticipantsSelected(formData.evaluatorParticipants);
    }, [formData.evaluatorParticipants]);

    useEffect(() => {
        if (participantsSelected.length > 0) setAllChecked(participantChecked.length === participantsSelected.length ? true : false);
    }, [participantChecked]);

    const removeParticipantFromList = id => {
        const newParticipants = formData.evaluatorParticipants.filter(
            participant => participant.idParticipant !== id,
        );

        setFormData(prevState => {
            return {...prevState, evaluatorParticipants: newParticipants};
        });
    };

    const sendReminder = () => {
        if (participantChecked.length > 0) {
            const data = {
                idCycle: formData.idCycle,
                typeMail: 2,
                typeSendTo: 2,
                mails: participantChecked
            };
            serviceRabbitMq.sendCallOrReminder(data).then(response => {
                if (!response.error) {
                }
            });
        } else {
            snackActions.error(t('CYCLES.JOURNEYS.SELECT_PARTICIPANT'));
        }
    }

    const sendEmail = () => {
        if (participantChecked.length > 0) {
            window.location = 'mailto:' + participantChecked;
        } else {
            snackActions.error(t('CYCLES.JOURNEYS.SELECT_PARTICIPANT'));
        }
    }

    const handleChangeRecivers = (e, participant, index) => {
        const newValue = e.target.value;
        const updatedParticipant = {...participant, numReceivers: newValue};
        const updatedParticipants = [...formData.evaluatorParticipants];
        updatedParticipants[index] = updatedParticipant;

        setFormData({...formData, evaluatorParticipants: updatedParticipants});
    };

    const handleChangeDaysAdded = (e, participant, index) => {
        const newExtraTimeLimit = e.target.value;
        const updatedParticipant = {...participant, extraTimeLimit: newExtraTimeLimit};
        const updatedParticipants = [...formData.evaluatorParticipants];
        updatedParticipants[index] = updatedParticipant;

        setFormData({...formData, evaluatorParticipants: updatedParticipants});
    };

    const [openLoading, setOpenLoading] = useState(false);

    const getDocument = (participant, reportType = 'report') => {
        setOpenLoading(true);

        let servicePromise;

        if (reportType === 'report') {
            const file = {
                idEvaluatorParticipant: participant.idEvaluatorParticipant,
                fileName: participant.participantName + " " + participant.participantSurname + " " + t("LABEL.REPORT"),
                fileType: "participant"
            };
            servicePromise = serviceFiles.getPdfEvaluator(file);

        } else {
            setOpenLoading(false);
            snackActions.error(t("EVALUATOR.ERROR_REPORT"));
            return;
        }


        servicePromise
            .then(() => {
                // Lógica en caso de éxito
            })
            .catch(error => {
                snackActions.error(t('EVALUATOR.ERROR_' + reportType.toUpperCase()));
            })
            .finally(() => {
                setOpenLoading(false);
            });
    };

    const sendDocument = (participant, reportType = 'report') => {
        setOpenLoading(true);

        let servicePromise;

        if (reportType === 'report') {
            const file = {
                idEvaluatorParticipant: participant.idEvaluatorParticipant,
                fileName: participant.participantName + " " + participant.participantSurname + " " + t("LABEL.REPORT"),
                fileType: "participant"
            };
            servicePromise = serviceFiles.sendPdfEvaluator(file);

        } else {
            setOpenLoading(false);
            snackActions.error(t("EVALUATOR.ERROR_REPORT"));
            return;
        }


        servicePromise
            .then(() => {
                // Lógica en caso de éxito
                snackActions.success(t("TEXT.REPORT_SEND"));
            })
            .catch(error => {
                snackActions.error(t('EVALUATOR.ERROR_' + reportType.toUpperCase()));
            })
            .finally(() => {
                setOpenLoading(false);
            });
    };

    const handleCopyToClipboard = (text) => { //Para copiar al portapapeles los códigos de participante y evaluador
      if (text) {
        navigator.clipboard.writeText(text);
        snackActions.success(t("EVALUATOR.COPY_CODE"));
      }
    };    
    
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false);
    const [deleteAction, setDeleteAction] = useState(null);

    // Función para abrir el diálogo de confirmación
    const handleOpenDeleteConfirmDialog = (action) => {
        setDeleteAction(action);
        setOpenDeleteConfirmDialog(true);
    };

    // Función para cerrar el diálogo de confirmación
    const handleCloseDeleteConfirmDialog = () => {
        setOpenDeleteConfirmDialog(false);
        setDeleteAction(null);
    };

    // Función para ejecutar la acción seleccionada
    const handleConfirmDeleteAction = () => {
        if (deleteAction === 'deleteAutoEval') {
            handleDeleteAutoEval();
        } else if (deleteAction === 'deleteEvaluations') {
            handleDeleteEvaluations();
        } else if (deleteAction === 'deleteBoth') {
            handleDeleteSessions();
        }
        handleCloseDeleteConfirmDialog();
    };    
    
    const [openDeleteOptionsDialog, setOpenDeleteOptionsDialog] = useState(false);
    const [selectedParticipantId, setSelectedParticipantId] = useState(null);
    const [selectedParticipant, setSelectedParticipant] = useState(null);
  
    const handleOpenDeleteOptionsDialog = (idEvaluatorParticipant) => {
      console.log("IdEvaluatorParticipant del participante seleccionado:", idEvaluatorParticipant);
      
      const participant = formData.evaluatorParticipants.find(
          (p) => p.idEvaluatorParticipant === idEvaluatorParticipant
      );
  
      // Validar si no hay autoevaluación ni evaluaciones
      if (participant?.isAutoevalDone !== 1 && participant?.totalReceiverResponses === 0) {
          snackActions.error(t('EVALUATOR.NOT_DELETE'));
          return;
      }
  
      setSelectedParticipantId(idEvaluatorParticipant);
      setSelectedParticipant(participant);
      setOpenDeleteOptionsDialog(true);
    };  
  
    const handleCloseDeleteOptionsDialog = () => {
      setOpenDeleteOptionsDialog(false);
      setSelectedParticipantId(null);
    };
  
    const handleDeleteSessions = async () => { //Para eliminar autoevaluación y evaluaciones de colaboradores
      try {
          console.log("IdEvaluatorParticipant seleccionado para eliminar todo:", selectedParticipantId);
  
          // Eliminar la autoevaluación
          await serviceEvaluatorData.deleteParticipantAutoEval(selectedParticipantId);
  
          // Eliminar evaluaciones
          await serviceEvaluatorData.deleteParticipantEvaluations(selectedParticipantId);
  
          snackActions.success(t('EVALUATOR.DELETE_BOTH_SUCCESS'));
      } catch (error) {
          console.error("Error al borrar la autoevaluación y las evaluaciones:", error);
          snackActions.error(t('EVALUATOR.DELETE_BOTH_ERROR'));
      } finally {
          setOpenDeleteOptionsDialog(false);
          setSelectedParticipantId(null); // Reiniciar selección
      }
    };
    
    const handleDeleteAutoEval = async () => { //Para eliminar la autoevaluación
      try {
          console.log("Iniciando la eliminación de la autoevaluación");
          const response = await serviceEvaluatorData.deleteParticipantAutoEval(selectedParticipantId);
          console.log("Respuesta del servidor:", response);
          snackActions.success(t('EVALUATOR.AUTOEVAL_DELETE_SUCCESS'));
      } catch (error) {
          console.error("Error al eliminar la autoevaluación:", error);
          snackActions.error(t('EVALUATOR.AUTOEVAL_DELETE_ERROR'));
      } finally {
          handleCloseDeleteOptionsDialog();
      }
    };  
    
    const handleDeleteEvaluations = async () => { //Para eliminar evaluaciones de colaboradores
      try {
          console.log("IdEvaluatorParticipant para eliminar evaluaciones:", selectedParticipantId);
    
          const response = await serviceEvaluatorData.deleteParticipantEvaluations(selectedParticipantId);
          console.log("Respuesta del servicio:", response);
    
          if (response.error) {
              throw new Error(response.message);
          }    
          snackActions.success(t('EVALUATOR.EVAL_DELETE_SUCCESS'));
      } catch (error) {
          console.error("Error al borrar las evaluaciones:", error);
          snackActions.error(t('EVALUATOR.EVAL_DELETE_ERROR'));
      } finally {
          setOpenDeleteOptionsDialog(false);
      }
    };

    return (

        <>
            <Dialog
              open={openConfirmDialog}
              onClose={() => setOpenConfirmDialog(false)}
              hasCloseBtn={false}
              title={t("CONFIRM.SEND_EMAIL")}
              actions={
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8, width: '100%' }}>
                  <Button
                    variant='contained'
                    onClick={() => setOpenConfirmDialog(false)}
                    sx={{
                      backgroundColor: '#da4f49',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#983733',
                      },
                    }}
                  >
                    {t("LABEL.FALSE")}
                  </Button>
                  <Button
                    variant='contained'
                    onClick={() => {
                      setOpenConfirmDialog(false);
                      sendDocument(currentParticipant, 'report');
                    }}
                    sx={{
                      backgroundColor: '#009900',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#1b5e20',
                      },
                    }}
                  >
                    {t("LABEL.TRUE")}
                  </Button>
                </Box>
              }
            >
              <Box p={2} textAlign="center">
                {t("CONFIRM.SEND_EMAIL")}
              </Box>
            </Dialog>

            <Dialog
            title={t("TEXT.LOADING")}
            open={openLoading}
            hasCloseBtn={false}
            content={
                <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
                }}>
                <Box><CircularProgress /></Box>
                <br />
                <Box>{t("EVALUATOR.LOADING_DATA")}</Box>
                </div>
            }
            />

            <Card>
                {openTable && (
                    <Dialog
                        disabled
                        open={openTable ? true : false}
                        width='70%'
                        height='75%'
                        hasCloseBtn={false}
                        title={t('CYCLES.SEARCH_PARTCIPANTS')}
                        close={() => setOpenTable(false)}
                        content={
                            <TableParticipants
                                setParticipantsSelected={setParticipantsSelected}
                                participantsSelected={participantsSelected}
                            />
                        }
                        actions={
                            <Box display='flex' width='100%' justifyContent='flex-end'>
                                <Button
                                    sx={{mr: 1}}
                                    variant='contained'
                                    color='error'
                                    onClick={() => {
                                        setOpenTable(false);
                                    }}
                                    aria-label='move selected left'>
                                    {t('CYCLES.OUT')}
                                </Button>
                                <Button
                                    variant='contained'
                                    color='success'
                                    onClick={() => {
                                        setFormData(prevState => {
                                            return {
                                                ...prevState,
                                                evaluatorParticipants: participantsSelected,
                                            };
                                        });
                                        setOpenTable(false);
                                    }}
                                    aria-label='move selected left'>
                                    {t('CYCLES.SAVE_FORM_CHANGES')}
                                </Button>
                            </Box>
                        }
                    />
                )}
                <Accordion expanded={true}>
                    <AccordionSummary
                        sx={{
                            bgcolor: 'grey.main',
                            paddingLeft: '20px'
                        }}
                    >
                        <Typography variant='subtitle1'>
                            {t('TEXT.SUBTITLE_PARTICIPANTS')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ overflowX: 'auto', maxWidth: '1500px' }}>
                        {/*<Typography variant='caption'>
                            {!formData.evaluatorParticipants.length
                                ? t('EVALUATOR.ADD_PARTICIPANTS')
                                : t('EVALUATOR.EDIT_PARTICIPANTS')}
                        </Typography>

                        <Grid container spacing={1} display={'flex'} justifyContent={'flex-start'}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Button
                                    variant='contained'
                                    fullWidth
                                    size='medium'
                                    onClick={() => setOpenTable(true)}
                                    sx={{
                                        textTransform: 'none',
                                    }}
                                    endIcon={<VisibilityIcon />}
                                >
                                    {t('CYCLES.PARCIPANT_LIST')}
                                </Button>
                            </Grid>
                        </Grid>*/}
                        <Box sx={{ width: '1500px' }}>
                        <List sx={{ maxWidth: '1500px'}}>
                            {formData.evaluatorParticipants?.length > 0 && (
                                <Typography color='primary'>
                                    <Checkbox
                                        sx={{p: 1}}
                                        onChange={e => handleAllCheck(e)}
                                        checked={allCheked}
                                    />

                                    {t('CYCLES.JOURNEYS.SELECT_ALL')}
                                </Typography>
                            )}
                            <Grid container spacing={1} key={listReload} sx={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                                {formData?.evaluatorParticipants.map((participant, index) => {
                                    console.log(participant)
                                    if (participant != null) {
                                        return (
                                        <>
                                            <ListItem
                                                key={participant.idParticipant}
                                                sx={{
                                                    bgcolor: 'theme.palette.primary.main',
                                                    marginTop: 2
                                                }}
                                            >
                                                <Grid container spacing={1} >
                                                    <Grid item xs={0.5} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                                        <ListItemIcon>
                                                            <Checkbox
                                                                value={participant.participantEmail}
                                                                sx={{p: 0}}
                                                                onChange={e => handleCheckbox(e)}
                                                                checked={checkList(participant.participantEmail)}
                                                            //checked={() => { return participantChecked.indexOf(participant.email) !== -1 ? true : false}}
                                                            />
                                                        </ListItemIcon>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                      {(participant.totalReceiverResponses > 0 || participant.isAutoevalDone !== null) ? (
                                                        // SÍ tiene evaluaciones o autoevaluaciones → Mostrar Tooltip, cursor pointer y permitir clic
                                                        <Tooltip title={t('CONFIRM.DELETE_TOOLTIP')}>
                                                          <Box
                                                            sx={{
                                                              cursor: 'pointer',
                                                              display: 'inline-block'
                                                            }}
                                                            onClick={() => handleOpenDeleteOptionsDialog(participant.idEvaluatorParticipant)}
                                                          >
                                                            <Typography variant='caption' component='div'>
                                                              {participant?.participantName && participant?.participantSurname
                                                                ? `${participant.participantName} ${participant.participantSurname}`
                                                                : t('CYCLES.NO_USER_DATA')}
                                                            </Typography>
                                                            <Typography
                                                              sx={{ display: 'inline', lineHeight: '3em' }}
                                                              component='span'
                                                              variant='body2'
                                                              color='text.primary'
                                                            >
                                                              {participant.participantEmail ? participant.participantEmail : t('CYCLES.NO_EMAIL_DATA')}
                                                            </Typography>
                                                          </Box>
                                                        </Tooltip>
                                                      ) : (
                                                        // NO tiene evaluaciones NI autoevaluaciones → Sin Tooltip, cursor default y sin interacción
                                                        <Box
                                                          sx={{
                                                            cursor: 'default',
                                                            display: 'inline-block'
                                                          }}
                                                        >
                                                          <Typography variant='caption' component='div'>
                                                            {participant?.participantName && participant?.participantSurname
                                                              ? `${participant.participantName} ${participant.participantSurname}`
                                                              : t('CYCLES.NO_USER_DATA')}
                                                          </Typography>
                                                          <Typography
                                                            sx={{ display: 'inline', lineHeight: '3em' }}
                                                            component='span'
                                                            variant='body2'
                                                            color='text.primary'
                                                          >
                                                            {participant.participantEmail ? participant.participantEmail : t('CYCLES.NO_EMAIL_DATA')}
                                                          </Typography>
                                                        </Box>
                                                      )}
                                                    </Grid>

                                                    <Grid item xs={1}>
                                                        <ListItemText
                                                            primaryTypographyProps={{fontSize: 14}}
                                                            primary={
                                                                <Typography variant='caption' component='div'>
                                                                    {t('EVALUATOR.PARTICIPANT_CODE')}
                                                                </Typography>
                                                            }
                                                            secondary={
                                                                <Typography
                                                                    sx={{
                                                                        display: 'inline', justifyContent: 'center',
                                                                        alignItems: 'center', lineHeight: '3em'
                                                                    }}
                                                                    component='span'
                                                                    variant='body2'
                                                                    color='text.primary'
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => handleCopyToClipboard(participant?.participantCode || "")}
                                                                    >                                                                    
                                                                    {participant?.participantCode || ""}
                                                                </Typography>
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1.2}>
                                                        <ListItemText
                                                            primaryTypographyProps={{fontSize: 14}}
                                                            primary={
                                                                <Typography variant='caption' component='div'>
                                                                    {t('EVALUATOR.RECEIVER_CODE')}
                                                                </Typography>
                                                            }
                                                            secondary={
                                                                <Typography
                                                                    sx={{
                                                                        display: 'inline', justifyContent: 'center',
                                                                        alignItems: 'center', lineHeight: '3em'
                                                                    }}
                                                                    component='span'
                                                                    variant='body2'
                                                                    color='text.primary'
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => handleCopyToClipboard(participant?.receiverCode || "")}
                                                                    >
                                                                    {participant?.receiverCode || ""}
                                                                </Typography>
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1} sx={{paddingLeft: '0px !important'}}>
                                                        <ListItemText
                                                            primaryTypographyProps={{fontSize: 14}}
                                                            primary={
                                                                <Typography variant='caption' component='div'>
                                                                    {t('EVALUATOR.NUMB_RECEIVER')}
                                                                </Typography>
                                                            }
                                                            secondary={
                                                                <TextField
                                                                    sx={{ width: '80%', lineHeight: '3em' }}
                                                                    size='small'
                                                                    type="number"                                                                
                                                                    name="quantity"
                                                                    value={participant?.numReceivers ?? 0}
                                                                    onChange={e => {
                                                                        handleChangeRecivers(e, participant, index)
                                                                    }}
                                                                    inputProps={{
                                                                        min: 0,
                                                                    }}
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1}>
                                                        <ListItemText
                                                            sx={{marginLeft: '5px'}}
                                                            primaryTypographyProps={{fontSize: 14}}
                                                            primary={
                                                                <Typography variant='caption' component='div'>
                                                                    {t('EVALUATOR.RESPONDED')}
                                                                </Typography>
                                                            }
                                                            secondary={
                                                                <Typography
                                                                    sx={{
                                                                        display: 'inline', justifyContent: 'center',
                                                                        alignItems: 'center', lineHeight: '3em'
                                                                    }}
                                                                    component='span'
                                                                    variant='body2'
                                                                    color='text.primary'>
                                                                    {participant?.totalReceiverResponses || 0}
                                                                </Typography>
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={1.2}>
                                                        <ListItemText
                                                            primaryTypographyProps={{fontSize: 14}}
                                                            primary={
                                                                <Typography variant='caption' component='div'>
                                                                    {t('EVALUATOR.DAYS_ADED')}
                                                                </Typography>
                                                            }
                                                            secondary={
                                                                <TextField
                                                                    sx={{ width: '50%', lineHeight: '3em' }}
                                                                    size='small'
                                                                    type="number"
                                                                    value={participant.extraTimeLimit ?? 0}
                                                                    onChange={e => handleChangeDaysAdded(e, participant, index)}
                                                                    inputProps={{min: 0}}
                                                                />
                                                            }
                                                        />
                                                    </Grid>

                                                    <Grid item xs={0.8}>
                                                        <ListItemText
                                                            sx={{ padding: 0, lineHeight: '1em' }}
                                                            primaryTypographyProps={{ fontSize: 14 }}
                                                            primary={
                                                                <Tooltip title={t("LABEL.GRADE_GLOBAL")}>
                                                                    <School sx={{ height: '0.8em', width: '0.8em' }} />
                                                                </Tooltip>
                                                            }
                                                            secondary={
                                                                < Typography
                                                                    sx={{
                                                                        display: 'inline', justifyContent: 'center',
                                                                        alignItems: 'center', lineHeight: '2.3em'
                                                                    }}
                                                                    component='span'
                                                                    variant='body2'
                                                                    color='text.primary'>
                                                                    {participant?.nota_Total?.toFixed(1) || ""}
                                                                </Typography>
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={0.8}>
                                                        <ListItemText
                                                            primaryTypographyProps={{ fontSize: 14 }}
                                                            primary={
                                                                <Typography variant='caption' component='div'>
                                                                    {t("LABEL.GRADE_QTR")}
                                                                </Typography>
                                                            }
                                                            secondary={
                                                                < Typography
                                                                    sx={{
                                                                        display: 'inline', justifyContent: 'center',
                                                                        alignItems: 'center', lineHeight: '3em'
                                                                    }}
                                                                    component='span'
                                                                    variant='body2'
                                                                    color='text.primary'>
                                                                    {participant?.nota_QTR?.toFixed(1) || ""}
                                                                </Typography>
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={0.8}>
                                                        <ListItemText
                                                            primaryTypographyProps={{ fontSize: 14 }}
                                                            primary={
                                                                <Typography variant='caption' component='div'>
                                                                    {t("LABEL.GRADE_AUTOEVAL")}
                                                                </Typography>
                                                            }
                                                            secondary={
                                                                < Typography
                                                                    sx={{
                                                                        display: 'inline', justifyContent: 'center',
                                                                        alignItems: 'center', lineHeight: '3em'
                                                                    }}
                                                                    component='span'
                                                                    variant='body2'
                                                                    color='text.primary'>
                                                                    {participant?.nota_AutoEval?.toFixed(1) || ""}
                                                                </Typography>
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={0.4}>
                                                        <ListItemText
                                                            sx={{ padding:0 }}
                                                            primaryTypographyProps={{ fontSize: 14 }}
                                                            primary={
                                                                <Tooltip title={t("LABEL.REPORT")}>
                                                                    <Summarize sx={{ height: '0.8em', width: '0.8em' }} />
                                                                </Tooltip>
                                                            }
                                                            secondary={
                                                                <>
                                                                    {participant?.numReceivers !== 0 && participant?.isAutoevalDone == 1 && 
                                                                        (participant?.totalReceiverResponses > 3 || participant?.numReceivers < 4 && participant?.totalReceiverResponses == participant?.numReceivers)
                                                                        && participant.idEvaluatorType && (
                                                                        <Tooltip title={t('WORKSHEET.DOWNLOAD')}>
                                                                            <IconButton
                                                                                sx={{
                                                                                    display: 'inline-flex', justifyContent: 'left',
                                                                                    alignItems: 'start', lineHeight: '1em', marginLeft: "-8px"
                                                                                }}
                                                                                color="inherit"
                                                                                onClick={() => {
                                                                                    setOpenLoading(true);
                                                                                    getDocument(participant, 'report')
                                                                                        .then(() => {
                                                                                            setOpenLoading(false);
                                                                                        })
                                                                                        .catch(() => {
                                                                                            setOpenLoading(false);
                                                                                        });
                                                                                    
                                                                                }}
                                                                            >
                                                                                <Summarize sx={{ height: '0.8em', width: '0.8em' }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        )}
                                                                </>
                                                            }
                                                        />
                                                    </Grid>
                                                    <Grid item xs={0.4}>
                                                        <ListItemText
                                                            sx={{ padding: 0 }}
                                                            primaryTypographyProps={{ fontSize: 14 }}
                                                            primary={
                                                                <Tooltip title={t("BUTTON.SEND_REPORT")}>
                                                                    <MailRounded sx={{ height: '0.8em', width: '0.8em' }} />
                                                                </Tooltip>
                                                            }
                                                            secondary={
                                                                <>
                                                                    {participant?.numReceivers !== 0 && participant?.isAutoevalDone == 1 &&
                                                                        (participant?.totalReceiverResponses > 3 || participant?.numReceivers < 4 && participant?.totalReceiverResponses == participant?.numReceivers)
                                                                        && participant.idEvaluatorType && (
                                                                            <Tooltip title={t('BUTTON.SEND_REPORT')}>
                                                                                <IconButton
                                                                                    sx={{
                                                                                        display: 'inline-flex', justifyContent: 'left',
                                                                                        alignItems: 'start', lineHeight: '1em', marginLeft: "-8px"
                                                                                    }}
                                                                                    color="inherit"
                                                                                    onClick={() => handleOpenConfirmDialog(participant)}
                                                                                >
                                                                                    <MailRounded sx={{ height: '0.8em', width: '0.8em' }} />
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        )}
                                                                </>
                                                            }
                                                        />
                                                    </Grid>

                                                    <Grid item xs={0.5}>
                                                        <DeletePopover
                                                            remove={() => {
                                                                removeParticipantFromList(participant.idParticipant, index);
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </ListItem>
                                            <Divider />
                                        </>
                                        );
                                    }
                                })}
                            </Grid>
                            {formData?.evaluatorParticipants.length > 0 && (
                                <Grid container spacing={1} display={'flex'} justifyContent={'flex-end'}>
                                    <Grid item xs={12} sm={4} >
                                        <Button
                                            fullWidth
                                            variant='contained'
                                            color='secondary'
                                            sx={{mt: 2}}
                                            onClick={() => {sendEmail()}}>
                                            {t('CYCLES.SEND_MAIL')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </List></Box>
                    </AccordionDetails>
                </Accordion>
            </Card>

            <Dialog //Dialog de confirmación de borrado
                open={openDeleteConfirmDialog}
                onClose={handleCloseDeleteConfirmDialog}
            >
                <DialogTitle sx={{textAlign: 'center'}}>
                    {t('CONFIRM.DELETE_CONFIRM')}
                </DialogTitle>
                <DialogActions
                    sx={{
                        justifyContent: 'center',
                        gap: '10px'
                    }}
                >
                    <Button
                        onClick={handleCloseDeleteConfirmDialog}
                        color="error"
                        variant="contained"
                        sx={{ width: '65px' }}
                    >
                        {t('LABEL.FALSE')}
                    </Button>
                    <Button
                        onClick={handleConfirmDeleteAction}
                        color="primary"
                        variant="contained"
                        sx={{ width: '65px' }}
                    >
                        {t('LABEL.TRUE')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog //Dialog con los botones de las acciones de borrado
              open={openDeleteOptionsDialog}
              onClose={handleCloseDeleteOptionsDialog}
            >
              <DialogTitle>
                  {t('CONFIRM.DELETE_EVALUATORS')}  
                  {selectedParticipant && (
                      <span style={{ color: '#0085b4' }}>
                          {` ${selectedParticipant.participantName || ''} ${selectedParticipant.participantSurname || ''}`}
                      </span>
                  )}:
              </DialogTitle>
              <DialogActions
                  sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '3px',
                      rowGap: '8px',
                      flexWrap: 'wrap'
                  }}
              >
                  <Button
                      onClick={() => handleOpenDeleteConfirmDialog('deleteAutoEval')}
                      color="primary"
                      variant="contained"
                      disabled={selectedParticipant?.isAutoevalDone !== 1}  // Deshabilitado si no hay autoevaluación
                      sx={{
                          height: 'auto',
                          minWidth: '100px',
                          maxWidth: '120px',
                          whiteSpace: 'normal',
                          textAlign: 'center',
                          padding: '8px',
                          fontSize: '0.8rem',
                      }}
                  >
                      {t('BUTTON.DELETE_AUTOEVAL')}
                  </Button>
                  <Button
                      onClick={() => handleOpenDeleteConfirmDialog('deleteEvaluations')}
                      color="primary"
                      variant="contained"
                      disabled={selectedParticipant?.totalReceiverResponses === 0}  // Deshabilitado si no hay evaluaciones
                      sx={{
                          height: 'auto',
                          minWidth: '100px',
                          maxWidth: '120px',
                          whiteSpace: 'normal',
                          textAlign: 'center',
                          padding: '8px',
                          fontSize: '0.8rem',
                      }}
                  >
                      {t('BUTTON.DELETE_EVALS')}
                  </Button>
                  <Button
                      onClick={() => handleOpenDeleteConfirmDialog('deleteBoth')}
                      color="primary"
                      variant="contained"
                      disabled={
                          selectedParticipant?.isAutoevalDone !== 1 || selectedParticipant?.totalReceiverResponses === 0
                      }  // Deshabilitado si falta alguna de las dos: evaluación o autoevaluación
                      sx={{
                          height: 'auto',
                          minWidth: '120px',
                          maxWidth: '150px',
                          whiteSpace: 'normal',
                          textAlign: 'center',
                          padding: '8px',
                          fontSize: '0.8rem',
                      }}
                  >
                      {t('BUTTON.DELETE_BOTH')}
                  </Button>
                  <Button
                      onClick={handleCloseDeleteOptionsDialog}
                      color="error"
                      variant="contained"
                      sx={{
                          height: 'auto',
                          minWidth: '100px',
                          maxWidth: '120px',
                          whiteSpace: 'normal',
                          textAlign: 'center',
                          padding: '8px',
                          fontSize: '0.8rem',
                      }}
                  >
                      {t('BUTTON.CANCEL')}
                  </Button>
              </DialogActions>
            </Dialog>
        </>
    );
};

export default SectionParticipants;