import React, { useState, useEffect} from 'react'
import { Button, Box, Typography } from '@mui/material';
import { Add, AccessTimeFilled } from '@mui/icons-material';

const EvButton = ({type, size, color, background, borderColor, disabled, onClick, displayX, dateEnd, extended, disablePointer, participant, forcePointer}) => {
  const [finished, setFinished] = useState(false);

  useEffect(() => {
    const date = new Date()
    setFinished(date >= Date.parse(dateEnd))
  }, [dateEnd])
  
  const colorEv = () => {
    if (background === 'full' || borderColor  === 'full') {
      return '#00CC99'
    } else if (background === 'half' || borderColor  === 'half') {
      return '#FF9933'
    } else if (background === 'empty' || borderColor  === 'empty') {
      return '#FC4646'
    }
  } 

  return (
    <div 
      style={{
        position:'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '47px', // Tamaño fijo en px para que no varía según la resolución
        height: '50px', // Tamaño fijo en px
        cursor: forcePointer ? "pointer" : disablePointer ? "default" : displayX ? "inherit" : "pointer",
      }} 
      onClick={onClick}
    >
      <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '5px',
            backgroundColor: finished && type === 1 
                              ? '#0085b4' 
                              : finished && type === 2 && !displayX
                              ? colorEv()
                              : `${background} !important`,
            borderWidth: '2px',
            borderStyle: 'solid',
            borderColor: displayX 
                            ? 'lightgrey'
                            : finished && type === 1 
                            ? '#0085b4' 
                            : finished && type === 2 && !displayX
                            ? colorEv()
                            : `${borderColor} !important`,
            height: size,
            minWidth: size,
            width: size,
            flexShrink: 0,
            position: 'relative'
        }}
        disabled={disabled} 
      >
        <Typography
          color={displayX ? 'lightgrey' 
                  : finished && type === 1 ? 'white !important'
                  : color}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: `calc(${size}/1.4)`,
            fontWeight: '600',
            height: '100%',
            minWidth: '100%',
            marginTop: '3px',
          }}
        >
          EV
        </Typography>
      </Box>

      {/* Contenedor del icono Add, para que sea independiente al de AccessTimeFilled */}
      <div style={{
        position: 'absolute',
        bottom: '3px', // Posición fija desde el borde inferior
        right: '15px', // Posición fija desde el borde derecho
        display: extended ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        width: '18px',
        height: '18px',
        zIndex: '99',
      }}>
        <svg  //Reemplazo del icono Add
          width="70px" // Ajusta el ancho del lienzo
          height="70px" // Ajusta la altura del lienzo
          viewBox="-7 -4 34 34" // Amplía el área visible para acomodar el trazo blanco
          style={{
            marginTop: '10px',
          }}
        >
          {/* Trazo blanco */}
          <path
            d="M12 2v20M2 12h20" // Representa el ícono "+"
            stroke="#ffffff" // Blanco para el trazo
            strokeWidth="10" // Grosor del trazo blanco
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
          {/* Ícono principal */}
          <path
            d="M12 2v20M2 12h20" // Representa el ícono "+"
            stroke="#ff5050" // Rojo vibrante para el ícono principal
            strokeWidth="5" // Grosor del ícono rojo
            strokeLinecap="round"
            strokeLinejoin="round"
            fill="none"
          />
        </svg>
      </div>

      {/* Contenedor del icono AccessTimeFilled */}

      {/* Esto es para poner la condición de cuando sale sólo el icono del "+" o los dos */}
      {(() => {
        const currentDate = new Date();
        const endDate = new Date(dateEnd);
        const extraTimeEnd = participant?.extraTimeLimit
          ? new Date(endDate.getTime() + participant.extraTimeLimit * 24 * 60 * 60 * 1000) // Sumar extraTimeLimit en días
          : endDate;

        // Ajustar la hora al final del día
        extraTimeEnd.setHours(23, 59, 59, 999);

          //console.log(`Fecha actual: ${currentDate}`);
          //console.log(`Fecha final Medidor: ${endDate}`);
          //console.log(`Fecha extendida del medidor (con extraTimeLimit): ${extraTimeEnd}`);          

          if (participant?.extraTimeLimit && currentDate <= extraTimeEnd) {
            return (
              <div style={{
                position: 'absolute',
                bottom: '-1px', // Posición fija desde el borde inferior
                right: '-2px', // Posición fija desde el borde derecho
                width: '16px',
                height: '16px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#ffffff', // Fondo blanco
                borderRadius: '50%',
                zIndex: '99',
              }}>
                <AccessTimeFilled
                  sx={{
                    color: '#ff5050',
                    fontSize: '20px', // Tamaño del icono
                    stroke: '#ffffff', //Borde
                    strokeWidth: '1.5'
                  }}
                />
              </div>
            )
          }
      })()}
    </div>
  )
}

export default EvButton;