import React, {useState, useEffect} from 'react'
import { makeStyles } from '@mui/styles'
import GearBlue from '@assets/images/owToolbox/Gear_Blue.png'
import GearHalfBlue from '@assets/images/owToolbox/Gear_Half_Blue.png'
import GearHalfQuestionBlue from '@assets/images/owToolbox/Gear_Half_Question_Blue.png'
import GearBlueQuestion from '@assets/images/owToolbox/Gear_Blue_Question.png'
import GearYellow from '@assets/images/owToolbox/Gear_Yellow.png'
import GearHalfYellow from '@assets/images/owToolbox/Gear_Half_Yellow.png'
import GearHalfQuestionYellow from '@assets/images/owToolbox/Gear_Half_Question_Yellow.png'
import GearYellowQuestion from '@assets/images/owToolbox/Gear_Yellow_Question.png'
import GearRed from '@assets/images/owToolbox/Gear_Red.png'
import GearHalfRed from '@assets/images/owToolbox/Gear_Half_Red.png'
import GearHalfQuestionRed from '@assets/images/owToolbox/Gear_Half_Question_Red.png'
import GearRedQuestion from '@assets/images/owToolbox/Gear_Red_Question.png'
import GearGreen from '@assets/images/owToolbox/Gear_Green.png'
import GearHalfGreen from '@assets/images/owToolbox/Gear_Half_Green.png'
import GearHalfQuestionGreen from '@assets/images/owToolbox/Gear_Half_Question_Green.png'
import GearGreenQuestion from '@assets/images/owToolbox/Gear_Green_Question.png'
import GearGray from '@assets/images/owToolbox/Gear_Gray.png'
import GearHalfGray from '@assets/images/owToolbox/Gear_Half_Gray.png'
import GearHalfQuestionGray from '@assets/images/owToolbox/Gear_Half_Question_Gray.png'
import GearGrayQuestion from '@assets/images/owToolbox/Gear_Gray_Question.png'

import GearOrange from '@assets/images/owToolbox/Gear_Orange.png'
import GearHalfOrange from '@assets/images/owToolbox/Gear_Half_Orange.png'
import GearHalfQuestionOrange from '@assets/images/owToolbox/Gear_Half_Question_Orange.png'
import GearOrangeQuestion from '@assets/images/owToolbox/Gear_Orange_Question.png'

import Circle from '@assets/images/owToolbox/circle.png'
import CircleOrange from '@assets/images/owToolbox/circleOrange.png'
import CircleQuestion from '@assets/images/owToolbox/circleQuestion.png'


import Destornillador from '@assets/images/owToolbox/Destornillador.png'
import DestornilladorBlanco from '@assets/images/owToolbox/Destornillador_blanco.png'
import { transformText } from '@helpers/common'
import sanitizeHtml from 'sanitize-html'

export const useMobileSheet = () => {
  const positionImage = {
    center: { justifyContent: 'center' },
    right: { justifyContent: 'flex-end' },
    left: { justifyContent: 'flex-start' }
  }

  const Title = ({ text, textColor, textAlign, backColor, index, total, isOpen, isClose }) => {
    return (
      <div id="useMobileSheet-Title"
        style={{
          position: 'sticky',
          boxSizing: 'border-box',
          background: backColor,
          padding: 15,
          top: 0,
          marginTop: isOpen ? '10px' : 0,
          marginBottom: isClose ? '10px' : 0,
          borderTopLeftRadius: isOpen ? '25px' : '0px',
          borderTopRightRadius: isOpen ? '25px' : '0px',
          borderBottomLeftRadius: isClose ? '25px' : '0px',
          borderBottomRightRadius: isClose ? '25px' : '0px',
          borderBottom: `2px solid #5B5B5B`
        }}
      >
        <p
          style={{ color: textColor, textAlign: textAlign, fontSize: 18 }}
          dangerouslySetInnerHTML={{
            __html: transformText(text)
          }}
        ></p>
      </div>
    )
  }
  const Image = props => {
    const length = sanitizeHtml(props.text, { allowedTags: [] })?.length
    // console.log('Texto:', sanitizeHtml(props.text, { allowedTags: [] }), 'Longitud:', length);
    const useStyles = makeStyles(theme => {
      return {
        container: props => ({
          display: 'inline-flex',
          minWidth: '100%',
          height: 'auto',
          width: '100%',
          marginTop: props.isOpen ? '10px' : 0,
          marginBottom: props.isClose ? '10px' : 0,
          background: props.backColor,
          ...positionImage[props.textAlign],
          borderTopLeftRadius: props.isOpen ? '25px' : '0px',
          borderTopRightRadius: props.isOpen ? '25px' : '0px',
          borderBottomLeftRadius: props.isClose ? '25px' : '0px',
          borderBottomRightRadius: props.isClose ? '25px' : '0px'
        }),
          containerImage: props => {
              return {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '0px',
                  backgroundImage: `url(${props.img})`,
                  height: `${length * 1.5}px`,
                  minHeight: length >= 75 && length <= 100 ? '45ex' : length > 300 ? '60ex' : length > 200 ? '55ex' : length > 100 ? '50ex' : '35ex',
                  backgroundSize: '100%',
                  backgroundRepeat: 'no-repeat',
                  minWidth: length >= 75 && length <= 100 ? '45ex' : length > 300 ? '60ex' : length > 200 ? '55ex' : length > 100 ? '50ex' : '35ex',
                  width: `${length * 1.5}px`,
                  backgroundPosition: 'center'
              }
          },
        text: props => {
          return {
            color: props.textColor || 'black',
            textAlign: 'center',
            fontSize: props.text?.length > 150 ? '1em' : '1.2em',
            width: 'auto',
            maxWidth: '70%',
            height: 'auto',
            lineHeight:
            length > 150
                ? '1.3em'
                : '1.5em'
          }
        }
      }
    })

    const classes = useStyles(props)

    return (
        <div id="useMobileSheet-Container" className={classes.container}>
        <div id="useMobileSheet-ContainerImage" className={classes.containerImage}>
          <p
            className={classes.text}
            dangerouslySetInnerHTML={{
              __html: transformText(props.text)
            }}
          />
        </div>
      </div>
    )
  }

  const Rectangle = ({
    text,
    textColor,
    textAlign,
    backColor,
    idType,
    index,
    total, 
    isOpen, 
    isClose 
  }) => {
    return idType === 42 ? (
      <div id="useMobileSheet-Rectangle"
        style={{
          // display: 'inline-block',
          background: 'white',
          paddingTop: 15,
          paddingBottom: 15,
          marginTop: isOpen ? '10px' : 0,
          marginBottom: isClose ? '10px' : 0,
          borderTopLeftRadius: isOpen ? '25px' : '0px',
          borderTopRightRadius: isOpen ? '25px' : '0px',
          borderBottomLeftRadius: isClose ? '25px' : '0px',
          borderBottomRightRadius: isClose ? '25px' : '0px',
          height: 'auto'
        }}
      >
        <div style={{background: backColor, width: '100%', height: '2.5em', display: 'flex', alignItems: 'center', padding: '15px'}}>
          <p
            style={{color: textColor, textAlign: textAlign,  minHeight: 20, background: backColor, width: '100%'}}
            dangerouslySetInnerHTML={{
              __html: transformText(text)
            }}
          />
        </div>
      </div>
    ) : (
      <div id="useMobileSheet-Rectangle"
        style={{
          background: backColor,
          padding: 15,
          marginTop: isOpen ? '10px' : 0,
          marginBottom: isClose ? '10px' : 0,
          borderTopLeftRadius: isOpen ? '25px' : '0px',
          borderTopRightRadius: isOpen ? '25px' : '0px',
          borderBottomLeftRadius: isClose ? '25px' : '0px',
          borderBottomRightRadius: isClose ? '25px' : '0px',
          border: idType === 41 ? `2px solid white` : null
        }}
      >
        <p
          style={{ color: textColor, textAlign: textAlign }}
          dangerouslySetInnerHTML={{
            __html: transformText(text)
          }}
        />
      </div>
    )
  }

  const Bullet = ({
    text,
    textColor,
    textAlign,
    backColor,
    idType,
    hasNumber,
    number,
    index,
    total,
    isOpen, 
    isClose 
  }) => {
    const bulletTypes = {
      50: {
        background: 'tranparent'
      },
      51: {
        background: '#0085B4'
      },
      52: {
        background: '#0085B4',
        width: 15,
        height: 15
      },
      53: {
        background: 'white',
        borderRadius: 50,
        border: '1px solid #0085B4',
        color: '#0085B4'
      },
      54: {
        background: 'white',
        borderRadius: 50,
        color: '#0085B4',
        fontWeight: 'bold'
      },
      55: {
        background: 'white',
        borderRadius: 50,
        border: '1px solid yellow',
        color: '#0085B4'
      },
      56: {
        backgroundColor: 'white',
        border: '2px solid orange',
        color: 'orange',
        fontWeight: 'bold'
      },
      57: {
        background: 'orange',
        width: 15,
        height: 15
      },
      58: {
        background: 'white',
        border: '1px solid #da4f49',
        color: '#da4f49'
      },
      59: {
        width: 15,
        height: 15
      },
      73: {
        width: 15,
        height: 15
      }
    }

    const textAlignFlex = () => {
      if (textAlign === 'left') {
        return 'start'
      } else if (textAlign === 'center') {
        return 'center'
      } else if (textAlign === 'right') {
        return 'end'
      }
    }

    return (
      <div id="useMobileSheet-Content"
        style={{
          display: 'flex',
          alignItems: (idType==73 || (idType <= 59 && idType >= 51)) ? 'flex-start' : 'center',
          background: backColor,
          padding: 8,
          justifyContent: textAlignFlex(),
          marginTop: isOpen ? '10px' : 0,
          marginBottom: isClose ? '10px' : 0,
          borderTopLeftRadius: isOpen ? '25px' : '0px',
          borderTopRightRadius: isOpen ? '25px' : '0px',
          borderBottomLeftRadius: isClose ? '25px' : '0px',
          borderBottomRightRadius: isClose ? '25px' : '0px'
        }}
      >
        {[59, 73].includes(idType) ? (
          <div id="useMobileSheet-Destornillador"
            style={{
              width: 30,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              margin: '-2px 20px 0 0', //Icono destornillador a la izquierda y texto en la misma vertical donde estaba
            }}
          >
            <img src={idType === 73 ? DestornilladorBlanco : Destornillador} alt='bullet' style={{ width: 30 }} />
          </div>
        ) : (
          <div>
            <div id="useMobileSheet-Other"
              style={{
                width: 26,
                height: 26,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 15,
                marginRight: 10,
                marginBlock: 5,
                ...bulletTypes[idType]
              }}
            >
              {hasNumber && (
                <p
                  style={{
                    fontSize: hasNumber ? 18 : 13,
                    color: bulletTypes[idType].color
                  }}
                >
                  {number}
                </p>
              )}
            </div>
          </div>
        )}
        <div>
          <p
            style={{ color: textColor, marginTop: hasNumber ? '5px' : '0px' }}
            dangerouslySetInnerHTML={{
              __html: transformText(text)
            }}
          />
        </div>
      </div>
    )
  }

  const Line = () => {
    return <hr />
  }

  const config = {
    10: { component: Title },
    20: { img: GearBlue, component: Image },
    21: { img: GearHalfBlue, component: Image },
    22: { img: GearGreen, component: Image },
    23: { img: GearHalfGreen, component: Image },
    24: { img: GearRed, component: Image },
    25: { img: GearHalfRed, component: Image },
    26: { img: GearYellow, component: Image },
    27: { img: GearHalfYellow, component: Image },
    28: { img: GearGray, component: Image },
    29: { img: GearHalfGray, component: Image },
	  43: { img: GearOrange, component: Image },
	  44: { img: GearHalfOrange, component: Image },		
    30: { img: GearBlueQuestion, component: Image },
    31: { img: GearHalfQuestionBlue, component: Image },
    32: { img: GearGreenQuestion, component: Image },
    33: { img: GearHalfQuestionGreen, component: Image },
    34: { img: GearRedQuestion, component: Image },
    35: { img: GearHalfQuestionRed, component: Image },
    36: { img: GearYellowQuestion, component: Image },
    37: { img: GearHalfQuestionYellow, component: Image },
    38: { img: GearGrayQuestion, component: Image },
    39: { img: GearHalfQuestionGray, component: Image },
	  45: { img: GearOrangeQuestion, component: Image },		
	  46: { img: GearHalfQuestionOrange, component: Image },
    40: { component: Rectangle },
    41: { img: GearBlueQuestion, component: Rectangle },
    42: { component: Rectangle },
    50: { component: Bullet, hasNumber: false },
    51: { component: Bullet, hasNumber: false },
    52: { component: Bullet, hasNumber: false },
    53: { component: Bullet, hasNumber: true },
    54: { component: Bullet, hasNumber: true },
    55: { component: Bullet, hasNumber: true },
    56: { component: Bullet, hasNumber: true },
    57: { component: Bullet, hasNumber: false },
    58: { component: Bullet, hasNumber: true },
    59: { component: Bullet, hasNumber: false },
    73: { component: Bullet, hasNumber: false },
    60: { component: Line },
    70: { img: Circle, component: Image },
    71: { img: CircleOrange, component: Image },
    72: { img: CircleQuestion, component: Image },
  }
  return {
    config
  }
}
