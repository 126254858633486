import React, { useState, useEffect } from 'react'
import apiRequest from '@helpers/apiRequest'
import AddButton from '@components/AddButton'
import BorramePdf1 from '@components/BorramePdf1'
import DynamicTable from '@components/DynamicTable'
import HeaderTitle from '@components/HeaderTitle'
import {
  IconButton,
  Button,
  Typography,
  Checkbox,
  Tooltip,
  Box
} from '@mui/material'
import { snackActions } from '@helpers/snackbarUtils'
import TableTemplateMailing from '@components/TableTemplateMailing'
import serviceRabbitMq from '@services/serviceRabbitMq'
import Dialog from '@components/Dialog'
import serviceParticipants from '@services/serviceParticipants'
import { useCommonStyles } from '@styles/common.style'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment';
import { EditRounded, Search } from '@mui/icons-material'

const Participants = () => {
  const commonClasses = useCommonStyles()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [participantsSelected, setParticipantsSelected] = useState([])
  const [showTemplatesDialog, setShowTemplatesDialog] = useState(false)
  const [mailData, setMailData] = useState([])
  const [checkAll, setCheckAll] = useState(false)
  const [globalSearch, setGlobalSearch] = useState('')
  const [columnSearch, setColumnSearch] = useState([])

  const [customFilter, setCustomFilter] = useState([])
  useEffect(() => {
        setCustomFilter([
            { searchField: 'isParatrooper', searchMode: 'equalTo', searchValue: false }
        ])
  }, [])
  
  const handleCheckbox = (isChecked, value) => {
    if (!isChecked) {
      setParticipantsSelected(prevState => {
        return [...prevState, value]
      })
    } else {
      const newPartipant = participantsSelected.filter(
        participant => participant.idParticipant !== value.idParticipant
      )
      setParticipantsSelected(newPartipant)
    }
  }

  const sendEmail = () => {
    const userSession = JSON.parse(sessionStorage.getItem('userSession'));
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 2)) {
      if (participantsSelected.length > 0 || checkAll) {
          const emails = []
          participantsSelected.forEach(participant => {
              emails.push(participant.participantEmail)
          })
          const dataMailData = {
              typeMail: 3,
              mails: emails,
              locale: [
                  {
                      idLanguage: 1,
                      idMailing: 3,
                      mailingBody: '',
                      mailingSubject: ''
                  }
              ]
          }

          setMailData(dataMailData)
          setShowTemplatesDialog(true)
      } else {
        snackActions.error(t('CYCLES.JOURNEYS.SELECT_PARTICIPANT'))
      }
    }else {
      snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
  }     
  }

  const getSearch = (global, columns) => {
    setGlobalSearch(global)
    setColumnSearch(columns)
  }

  const sendEmailParticipants = () => {
    if (checkAll) {
      apiRequest('post', 'Participants/List', {
        pageSize: 0,
        pageNumber: 0,
        globalSearch: globalSearch,
        search: columnSearch
      }).then(response => {
        if (!response.error) {
          const data = {
            MailTo: response.data.map(x => x.participantEmail),
            Locales: mailData.locale,
            isConvocated: false
          }

          serviceRabbitMq.sendDirectMail(data).then(response => {
            if (!response.error) {
              setShowTemplatesDialog(false)
            }
          })
        } else {
          // console.log(response.error)
        }
      })
    } else {
      const data = {
        MailTo: participantsSelected.map(x => x.participantEmail),
        Locales: mailData.locale
      }
        serviceRabbitMq.sendDirectMail(data).then(response => {
          if (!response.error) {
            setShowTemplatesDialog(false)
        }
      })
    }
  }

  const BOOLEANS = [
    {
      label: t('LABEL.FALSE'),
      value: '0'
    },
    {
      label: t('LABEL.TRUE'),
      value: '1'
    }
  ]

  const filterMap = value => {
    if (value == '') return ''
    let res = BOOLEANS.filter(x =>
      x.label.toLowerCase().includes(value.toLowerCase())
    )
    return res.length ? res[0].value : '|'
  }

  const ORDER = [
    {
      orderField: 'participantSurname',
      orderAsc: true
    }
  ]
  const COLUMNS = [
    {
      filterable: false,
      hideHeader: true,
      clickable: (
        <Tooltip title={t('CYCLES.JOURNEYS.SELECT_ALL')}>
          <Checkbox
            disableRipple
            sx={{ p: 0 }}
            checked={checkAll}
            onClick={() => {
              setCheckAll(!checkAll)
              setParticipantsSelected([])
            }}
          />
        </Tooltip>
      ),
      accessor: '',
      Header: 'Check',
      width: '5%',
      Cell: ({ row: { values } }) => {
        delete values['Check']
        const isChecked = participantsSelected.some(
          participant => participant.idParticipant === values.idParticipant
        )

        return (
          <Checkbox
            disableRipple
            sx={{ p: 0 }}
            checked={isChecked || checkAll}
            onClick={e => handleCheckbox(isChecked, values)}
          />
        )
      }
    },
    {
      accessor: '',
      Header: 'Info',
      filterable: false,
      hideHeader: true,
      width: '5%',
      Cell: ({ row: { values } }) => (
        <Box display='flex' flexDirection='row'>
          <Tooltip title={t('LABEL.EDIT')}>
            <IconButton
              aria-label='edit'
              color='icon_blue'
              style={{
                padding: '0px',
                marginRight: 15
              }}
              onClick={() => {
                handleShowParticipantDetail({
                  idParticipant: values.idParticipant,
                  type: 'UPDATE'
                })
              }}
            >
              <EditRounded fontSize='inherit' />
            </IconButton>
          </Tooltip>
        </Box>
      )
    },
    {
      accessor: 'idParticipant',
      Header: t('LABEL.ID'),
      filterable: false,
      width: '5%'
    },
    {
      accessor: 'participantName',
      Header: t('LABEL.NAME'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'participantSurname',
      Header: t('LABEL.SURNAME'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'companyName',
      Header: t('LABEL.COMPANY'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'participantPosition',
      Header: t('LOGIN.SIGN_UP.JOB_POSITION_PARTICIPANT'),
      hiddenColumn: true,
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'cycleName',
      Header: t('CYCLE_PARTICIPANTS.CYCLE'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'cycleTypeCode',
      Header: t('TEXT.CYCLE_TYPE'),
      hiddenColumn: true,
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'consultants',
      Header: t('LABEL.CONSULTANTS'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'cycleDateFromYear',
      Header: t('LABEL.DATE_INIT'),
      hiddenColumn: true,
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    /*{
      accessor: 'cycleDateTo',
      Header: t('LABEL.EV_END'),
      hiddenColumn: true,
      Cell: props => <Typography variant='body2'><Moment format='yyyy'>{props.value}</Moment></Typography>
    },*/
    {
      accessor: 'valuationCode',
      Header: t('LABEL.VALUATION'),
      hiddenColumn: true,
      Cell: props => <Typography variant='body2'>{props.value}</Typography>,
    },
    {
      accessor: 'participantCity',
      Header: t('LABEL.CITY'),
      hiddenColumn: true,
      Cell: props => <Typography variant='body2'>{props.value}</Typography>,
    },
    {
      accessor: 'participantZip',
      Header: t('LABEL.POSTCODE'),
      hiddenColumn: true,
      Cell: props => <Typography variant='body2'>{props.value}</Typography>,
    },
    {
      accessor: 'participantPhone',
      Header: t('LABEL.PHONE'),
      size: 5,
      Cell: props => <Typography variant='body2'>{props.value}</Typography>,
    },
    {
      accessor: 'participantEmail',
      Header: t('LABEL.EMAIL'),
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'countryName',
      Header: t('LABEL.COUNTRY'),
      hiddenColumn: true,
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'participantRemarks',
      Header: t('LABEL.REMARKS'),
      hiddenColumn: true,
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    },
    {
      accessor: 'incidentsGroup',
      Header: t('MENU_ADMIN.INCIDENTS'),
      hiddenColumn: true,
      Cell: props => <Typography variant='body2'>{props.value}</Typography>
    }
  ]

  const handleShowParticipantDetail = ({ idParticipant, type }) => {
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 2)) {
      serviceParticipants.getParticipantsDetails(idParticipant).then(response => {
        if (!response.error) {
          navigate(`/participantDetail`, {
            state: { data: response.data, type }
          })
        }
      })
    } else {
      snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
    }  
  }

  const handleNewParticipant = () => {
    
    const userSession = JSON.parse(sessionStorage.getItem('userSession'))
    if (userSession.userIdsRoles.find(idRole => idRole === 1 || idRole === 2)) {
        navigate('/participantDetail', { state: { data: {}, type: 'NEW' } })
      } else {
        snackActions.error(t('TEXT.FORBIDDEN.USER.DONT_PERMISSION_ACTION'));
    }   
  }

  return (
    <div className={commonClasses.container}>
      {showTemplatesDialog && (
        <Dialog
          width='70%'
          height='80%'
          open={showTemplatesDialog}
          hasCloseBtn={false}
          title={t('CYCLES.SEND_MAIL')}
          close={() => setShowTemplatesDialog(false)}
          content={<TableTemplateMailing setMailData={setMailData} mailData={mailData} disabled={true} />}
          actions={
            <Box display='flex' width='100%' justifyContent='flex-end'>
              <Button
                sx={{ mr: 1 }}
                variant='contained'
                color='error'
                onClick={() => {
                  setShowTemplatesDialog(false)
                }}
                aria-label='move selected left'
              >
                {t('BUTTON.CLOSE')}
              </Button>
              <Button
                id='btnSubmitEmail'
                variant='contained'
                onClick={() => sendEmailParticipants()}
                aria-label='move selected left'
                disabled={mailData.length === 0 }
              >
                {t('CYCLES.SEND_MAIL')}
              </Button>
            </Box>
          }
        />
      )}
      <HeaderTitle
        title={t('MENU_ADMIN.PARTICIPANTS')}
        subtitle={t('TEXT.SUBTITLE_PARTICIPANTS')}
      >
        <AddButton
          text={t('BUTTON.NEW_PARTICIPANT')}
          handleOnclick={handleNewParticipant}
        />
      </HeaderTitle>
      <DynamicTable
        columns={COLUMNS}
        endpoint='CycleParticipants/ListDetail'
        customFilter={customFilter}
        isGlobalSearch={true}
        isColumnsSearch={true}
        isOrdered={true}
        viewExcel={true}
        viewSendMail={true}
        handleSendMail={sendEmail}
        handleGetSearch={getSearch}
        nameExcel={t('MENU_ADMIN.PARTICIPANTS')}
        titleBodyExcel={t('PARTICIPANT.BODY_EXCEL')}
        orderColumn={ORDER}
      />
    </div>
  )
}

export default Participants
