import React,{useEffect, useState} from 'react';
import { makeStyles } from '@mui/styles';
import { useMobileSheet } from '@hooks/useMobileSheet';
import { useTranslation } from 'react-i18next'


const ContentMobile = ({ formData }) => {
    const { config } = useMobileSheet();
    const[langPosition, setLangPosition] = useState(0)
    const { i18n, t } = useTranslation()
    
    useEffect(() => {
            
        const lang = sessionStorage.getItem("lang")
        switch(lang){
            case 'es':
                setLangPosition(0)
                break;
              case 'en':
                setLangPosition(1)
                break;
              case 'it':
                setLangPosition(2)
                break;
              default:
                setLangPosition(0)
                break;
            }
        }, [formData, i18n, t ]);

    const logoMap = {
        'es': require('@assets/images/owToolbox/OWToolboxLogo_es.png'),
        'en': require('@assets/images/owToolbox/OWToolboxLogo_en.png'),
        'it': require('@assets/images/owToolbox/OWToolboxLogo_it.png')
    };

    const selectedLogo = logoMap[sessionStorage.getItem("lang")] || logoMap['es'];

    const useStyles = makeStyles(theme => ({
        container: props => ({
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            background: props?.mobileSheetColor,
            height: '100%',
            overflowY: 'scroll',
            boxSizing: 'border-box',
            '&::-webkit-scrollbar': {
                width: 0,
                height: 0
              },
        }),
        content: props => ({
            flex: 1,
            padding: '0px !important',
            background: props?.mobileSheetColor,
            backgroundColor: '#5B5B5B',
            boxSizing: 'border-box',
        }),
        footer: {
           backgroundColor: '#5B5B5B' },
    }));

    const classes = useStyles(formData);

    return (
        <div className={classes.container}>
            <div id="content-mobile" className={classes.content}>
                {formData.mobileSheetItems.map((item, i) => {
                    const Component = config[item?.idMobileSheetItemType]?.component;
                    if (Component) {
                      const props = {
                        key: item?.idMobileSheetItemType + '_' + i,
                        text: item?.mobileSheetItemLocale[langPosition]?.mobileSheetItemContent,
                        textColor: item?.mobileSheetItemTextColor,
                        backColor: item?.mobileSheetItemBackColor,
                        textAlign: item?.mobileSheetItemAlign,
                        img: config[item?.idMobileSheetItemType]?.img,
                        idType: item?.idMobileSheetItemType,
                        hasNumber: config[item?.idMobileSheetItemType]?.hasNumber,
                        number: item?.mobileSheetItemNumber,
                        index: item?.mobileSheetItemOrder,
                        total: formData.mobileSheetItems.length,
                        isOpen: item?.isOpenWorksheet,
                        isClose: item?.isCloseWorksheet
                    };
                        return <Component {...props} />;
                    }
                    return null;
                })}
            </div>
            {formData.mobileSheetLogo && (
                <div className={classes.footer}>
                    <img
                        src={selectedLogo}
                        alt='logofooterOW'
                        style={{ maxWidth: '100%', marginTop: '5px'}}
                    />
                </div>
            )}
        </div>
    );
};

export default ContentMobile;