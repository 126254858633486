import React,{useEffect, useState} from 'react';
import { makeStyles } from '@mui/styles';
import { useMobileSheet } from '@hooks/useMobileSheet';
import { useTranslation } from 'react-i18next'


  const ContentMobileForOWToolbox = ({ formData, selectedLanguage }) => {
      const { config } = useMobileSheet();
      const[langPosition, setLangPosition] = useState(parseInt( selectedLanguage - 1))
      
      const logoMap = {
        1: require('@assets/images/owToolbox/OWToolboxLogo_es.png'),
        2: require('@assets/images/owToolbox/OWToolboxLogo_en.png'),
        3: require('@assets/images/owToolbox/OWToolboxLogo_it.png')
    };
  
    const selectedLogo = logoMap[selectedLanguage] || logoMap[1];
  
    const useStyles = makeStyles(theme => {
        return {
            container: props => {
                return {
                    position: 'absolute',
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    background: props?.mobileSheetColor,
                    height: '100%',
                    overflowY: 'scroll',
                    boxSizing: 'border-box',
                    '&::-webkit-scrollbar': {
                        width: 0,
                        height: 0,
                    },
                };
            },
            content: props => {
                return {
                    flex: 1,
                    padding: '0px !important',
                    background: props?.mobileSheetColor,
                    backgroundColor: '#5B5B5B',
                    boxSizing: 'border-box',
                };
            },

            footer: {
                backgroundColor: '#5B5B5B',
                
            },
        };
    });
    const classes = useStyles(formData);

    return (
        <div className={classes.container}>
            <div id="content-mobile" className={classes.content}>
                {formData.mobileSheetItems.map((item, i) => {
                    if (item?.idMobileSheetItemType) {
                        const Component = config[item?.idMobileSheetItemType]?.component;
                        // console.log(config[item?.idMobileSheetItemType]);
                        const props = {
                            key: item?.idMobileSheetItemType + '_' + i,
                            text: item?.mobileSheetItemLocale?.find(
                                (locale) => locale.idLanguage == selectedLanguage
                            )?.mobileSheetItemContent,                         
                            textColor: item?.mobileSheetItemTextColor,
                            backColor: item?.mobileSheetItemBackColor,
                            textAlign: item?.mobileSheetItemAlign,
                            img: config[item?.idMobileSheetItemType]?.img,
                            idType: item?.idMobileSheetItemType,
                            hasNumber: config[item?.idMobileSheetItemType]?.hasNumber,
                            number: item?.mobileSheetItemNumber,
                            index: item?.mobileSheetItemOrder,
                            total: formData.mobileSheetItems.length,
                            isOpen: item?.isOpenWorksheet,
                            isClose: item?.isCloseWorksheet
                        };

                        return <Component {...props} />;
                    }
                    return null;
                })}
            </div>
            {formData.mobileSheetLogo && (
                <div className={classes.footer}>
                    <img
                        src={selectedLogo} 
                        alt='logofooterOW'
                        style={{ maxWidth: '100%', marginTop: '5px'}}
                    />
                </div>
            )}
        </div>
    );
};

export default ContentMobileForOWToolbox;